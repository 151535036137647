<template>
  <div>
    <div
      v-if="productSettingChanged && changePlanModalWindowState === changePlanState &&
        (showServerPluginUninstallWarning || (inactivePluginsString && !product.bundlesOnly))"
      class="fixed-warning-container"
    >
      <v-container>
        <v-row
          dense
          justify="center"
        >
          <v-col>
            <v-alert
              class="ma-auto warning-alert"
              :value="true"
              type="warning"
              dismissible
              data-cy="plugin-uninstall-warning"
            >
              <div
                v-if="showServerPluginUninstallWarning"
                v-t="'productDetails.serverPluginUninstallWarning'"
              />
              <v-divider
                v-if="showServerPluginUninstallWarning && inactivePluginsString"
                class="my-2"
              />
              <div v-if="inactivePluginsString">
                {{ $tc('productDetails.yourProductHas_count_inactivePluginInstalled', inactivePluginsCount) }}
                <b>{{ inactivePluginsString }}</b>
                <!-- eslint-disable-next-line vue-i18n/no-raw-text -->
                {{ '.' }}
                <span v-t="'productDetails.pleaseContactYourAccountManager'" />
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <core-element-modal-window
      class="product-change-plan-modal-window"
      :title="$t(`productDetails.${camelize(title)}`)"
      :button-name="$t('productDetails.changePlan')"
      :on-modal-changed="onModalChange"
    >
      <template
        v-if="changePlanModalWindowState === changePlanState"
        #content
      >
        <material-product-details-change-plan-cloud-form
          v-if="product.type === typeCloud"
          :product="product"
          @form-summary="onFormSummary"
        />
        <material-product-details-change-plan-server-form
          v-else-if="product.type === typeServer"
          :product="product"
          @form-summary="onFormSummary"
        />
      </template>
      <template
        v-else-if="changePlanModalWindowState === checkoutState"
        #content
      >
        <material-order-checkout
          :client-id="clientId"
          :order="order"
        />
      </template>
      <template
        v-else-if="changePlanModalWindowState === quotationCreationWaitingState"
        #content
      >
        <material-product-details-change-plan-quotation-creation-waiting-message />
      </template>
      <template
        v-else-if="changePlanModalWindowState === quotationCreationTimeoutState"
        #content
      >
        <material-product-details-change-plan-quotation-creation-timeout-message />
      </template>
    </core-element-modal-window>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex'
import { ROUTE_PAYMENT_CANCELED, ROUTE_INVOICES_AND_QUOTATIONS } from '@/constants/routeNames'
import {
  TYPE_SERVER,
  TYPE_CLOUD,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE,
  CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT,
  CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION,
  CHANGE_PLAN_MODAL_WINDOW_QUOTATION_CREATION_TIMEOUT_STATE
} from '@/constants/app'

export default {
  props: {
    clientId: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      plugins: null,
      typeServer: TYPE_SERVER,
      typeCloud: TYPE_CLOUD,
      closedState: CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED,
      changePlanState: CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE,
      checkoutState: CHANGE_PLAN_MODAL_WINDOW_STATE_CHECKOUT,
      quotationCreationWaitingState: CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION,
      quotationCreationTimeoutState: CHANGE_PLAN_MODAL_WINDOW_QUOTATION_CREATION_TIMEOUT_STATE
    }
  },
  computed: {
    ...mapState('productDetails', ['changePlanModalWindowState', 'availablePlugins']),
    ...mapState('order', ['order']),
    product () {
      const { clientId, contractId } = this.$route.params
      const contract = this.$store.getters['contract/contract']({ clientId, contractId })
      return contract.product
    },
    title () {
      switch (this.changePlanModalWindowState) {
        case CHANGE_PLAN_MODAL_WINDOW_STATE_WAITING_FOR_QUOTATION_CREATION:
        case CHANGE_PLAN_MODAL_WINDOW_QUOTATION_CREATION_TIMEOUT_STATE:
          return this.$i18n.t('productDetails.savingOrderAndGeneratingQuotation')
        default:
          return this.product.type === this.typeCloud
            ? this.$i18n.tc('productDetails.chooseNewParametersOfYourCloudSubscription', this.product.name)
            : this.$i18n.t('productDetails.changeServerSolution')
      }
    },
    showServerPluginUninstallWarning () {
      if (this.plugins && this.product.type === TYPE_SERVER) {
        for (const productPlugin of this.product.plugins) {
          const found = this.plugins.filter(p =>
            p.easy_price_book_product_id === productPlugin.easy_price_book_product_id).length

          if (!found) {
            return true
          }
        }
      }

      return false
    },
    inactivePluginsNames () {
      return this.product.plugins.reduce((filtered, plugin) => {
        if (!plugin.active) {
          filtered.push(plugin.name)
        }
        return filtered
      }, [])
    },
    inactivePluginsString () {
      return this.inactivePluginsNames ? this.inactivePluginsNames.join(', ') : false
    },
    inactivePluginsCount () {
      return this.inactivePluginsNames.length
    },
    productSettingChanged () {
      return Boolean(this.order)
    }
  },
  methods: {
    ...mapMutations('order', ['clearOrder']),
    ...mapMutations('productDetails', ['setChangePlanModalWindowState', 'setAvailablePlugins']),
    ...mapActions('productDetails', ['getAvailablePlugins']),
    camelize (string) {
      return this.$options.filters.camelize(string)
    },
    onFormSummary ({ plugins }) {
      this.plugins = plugins
    },
    onModalChange (opened) {
      const prevModalState = this.changePlanModalWindowState

      this.setChangePlanModalWindowState(
        opened
          ? CHANGE_PLAN_MODAL_WINDOW_STATE_CHANGE
          : CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED
      )

      if (opened) {
        this.getAvailablePlugins(this.$route.params)
        this.clearOrder()
      }

      if (!opened) {
        this.setChangePlanModalWindowState(
          CHANGE_PLAN_MODAL_WINDOW_STATE_CLOSED
        )

        if (prevModalState === this.checkoutState) {
          this.$router.push({ name: ROUTE_PAYMENT_CANCELED })
        } else if (
          prevModalState === this.quotationCreationTimeoutState ||
          prevModalState === this.quotationCreationWaitingState
        ) {
          this.$router.push({ name: ROUTE_INVOICES_AND_QUOTATIONS })
        }
      }
    }
  }
}
</script>

<style>
.border-thin {
  border: thin solid rgba(0, 0, 0, 0.12);
}

.warning-alert .v-icon {
  margin-top: auto;
  margin-bottom: auto
}
</style>
